import React from 'react';
import { graphql, PageProps } from 'gatsby';
import Layout from '../components/layout';
import { TemplateOssLicenseQuery } from '../../graphql-types';

const OssLicense = ({ data }: PageProps<TemplateOssLicenseQuery>): JSX.Element => {
  const { npmLicense } = data;
  if (!npmLicense) {
    throw new Error('npmLicense が取得できません。');
  }
  return (
    <Layout seo={{ title: `オープンソース ライセンス - ${npmLicense.name}` }}>
      <section className="section">
        <div className="container">
          <h1 className="title is-spaced">{npmLicense.name}</h1>
          {npmLicense.repository && (<p className="block"><a href={npmLicense.repository}>{npmLicense.repository}</a></p>)}
          <p className="content">{npmLicense.licenseText}</p>
        </div>
      </section>
    </Layout>
  );
};
export default OssLicense;

export const pageQuery = graphql`
  query TemplateOssLicense ($name: String!) {
    npmLicense(name: { eq: $name }) {
      name
      licenseText
      repository
    }
  }
`;
